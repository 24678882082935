import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import Video from '../components/video'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import EspaciadorVertical from '../components/espaciador-vertical'
import { Anecdotas } from '../components/estilos/estilos-de-articulo'
import Navegar from '../components/navegar'

// #region GraphQL
export const query = graphql`
  {
    allAnecdotasOvejasYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const AnecdotasOvejas = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allAnecdotasOvejasYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Anecdotas>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogaovejasappia01viadiceciliametellajpg')}
                title="Via di Cecilia Metella. Appia Antica. Roma."
                leyenda="Via di Cecilia Metella. Appia Antica. Roma."
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>Sorpresa en Roma</h2>
                <p>
                  Te cuento la anécdota. Caminaba por esa encantadora callecita cerca de la via
                  Appia Antica, sin vereda, tratando de que los autos no me llevaran por delante,
                  cuando sentí un extraño ruido a mis espaldas, pero verdaderamente extraño, nunca
                  antes escuchado por mí. Difícil de explicar ¿tal vez telas que se rozaban en gran
                  cantidad?
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3>Un ruido inusual</h3>
                <p>
                  Me di vuelta ¿qué podía ser ese inusual ruido? Vi cientos de ovejitas y en un
                  instante me encontré rodeada por ellas. Se rozaban unas con otras ocasionando el
                  «extraño» rumor. ¡En Roma! Se dirigían al mismo parque que yo, ja, ja, pero ellas
                  iban mucho más rápido, me dejaron atrás… Las corrí desconsolada, sobre todo porque
                  del apuro creí que estaba filmando pero me había equivocado. Qué rabia. Quería
                  alcanzarlas para volverlas a filmar pero fue imposible, sólo las registré
                  alejándose. Aquí ves a las encantadoras ovejitas.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                title="Te cuento qué me pasó cuando caminaba despreocupadamente."
                leyenda="Te cuento qué me pasó cuando caminaba despreocupadamente."
              >
                <Video
                  src="https://www.youtube.com/embed/IDS_znBJnDE"
                  title="Te cuento qué me pasó cuando caminaba despreocupadamente."
                />
              </FotoConLeyenda>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogaovejasappia02ovejasviadiceciliametellajpg')}
                title="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
                leyenda="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                title="Ovejitas con su pastor entrando al Parco della Caffarella. Es parte del Parco Regionale"
                leyenda="Ovejitas con su pastor entrando al Parco della Caffarella. Es parte del Parco Regionale"
              >
                <Video
                  src="https://www.youtube.com/embed/5NIRjSJ7rUM"
                  title="Ovejitas con su pastor entrando al Parco della Caffarella. Es parte del Parco Regionale"
                />
              </FotoConLeyenda>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogaovejasappia03ovejasviadiceciliametella2jpg')}
                title="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
                leyenda="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogaovejasappia04ovejasviadiceciliametella3jpg')}
                title="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
                leyenda="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogaovejasappia05ovejasviadiceciliametella4jpg')}
                title="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
                leyenda="Ovejas en via di Cecilia Metella, Appia Antica. Roma."
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                title="Ovejitas con su pastor en el Parco della Caffarella. Yéndose rápidamente."
                leyenda="Ovejitas con su pastor en el Parco della Caffarella. Yéndose rápidamente."
              >
                <Video
                  src="https://www.youtube.com/embed/gcNQtTlcZbM"
                  title="Ovejitas con su pastor en el Parco della Caffarella. Yéndose rápidamente."
                />
              </FotoConLeyenda>
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Anecdotas>
    </Marco>
  )
}
export default AnecdotasOvejas
